//@ts-check
import React from "react";
import { useDispatch } from "react-redux";
import { useI18n } from "components/lni18n";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";
import classes from './lecture.module.scss';
import LnDropDownMenu from "components/LnDropDownMenu";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { lectureDuck } from "./LectureDuck";

const PrintLecture = (props) => {

    const dispatch = useDispatch();
    const { languageService: t } = useI18n();
    const { currentLecture, printstyle } = useTypedSelector(state => state.lecture);
    const lastFeature = useTypedSelector(state => state.layout.lastFeature);

    if (!currentLecture) {
        return <WaitRipple />
    }

    const close = () => {
        if (lastFeature == null) {
            window.history.back();
        }
        else {
            dispatch({ type: lastFeature.type, payload: lastFeature.payload });
        }
    }

    const setStyle = (n) => {
        dispatch(lectureDuck.setPrintStyle(n));
    }

    if (!currentLecture) {
        return null;
    }


    return (
        <div className={classes.lecturePrint}  >

            <div className="d-print-none d-flex ">
                <h2 className="margin-bottom-medium ">{t.getText("lecture.handouts")}</h2>

                <button className="btn btn-primary btn-small ml-4 mb-2" onClick={() => window.print()}>{t.getText("print")}</button>


                <LnDropDownMenu
                    caret={true} direction="down"
                    toggleComponent={<div className="pointer">
                        {t.getText("type")}
                    </div>}
                    toogleClassName="btn btn-primary btn-small ml-3"
                    toogleTagName="div"
                >
                    <DropdownMenu tag="ul">

                        <DropdownItem tag="div" onClick={() => setStyle(3)} >
                            <div className="border p-2 ">
                                <img alt="" src="/imgs/printout3.svg" />
                            </div>
                        </DropdownItem>


                        <DropdownItem tag="div" onClick={() => setStyle(2)} >
                            <div className="border p-2">
                                <img alt="" src="/imgs/printout2.svg" />
                            </div>
                        </DropdownItem>

                    </DropdownMenu>
                </LnDropDownMenu>

                <button className="btn btn-primary btn-small ml-auto  mb-2" onClick={() => close()}>{t.getText("close")}</button>

            </div>
            <h2 className="margin-bottom-medium ">{currentLecture.Name}</h2>

            {currentLecture.Screens.map(s => {

                const img = s.Images.find(i => i.Hires);
                if (!img.Url) {
                    return null;
                }

                let retval = null;

                switch (printstyle) {

                    case 2:
                        retval =  <div className={" w-100 mb-5"} >
                            <img src={img.Url} alt="" className={ classes.type2 }  />
                        </div>
                        break;

                    case 3:
                        retval =  (
                            <div className={classes.handoutPage + " row"} >
                                <div className="col-6">
                                    <img src={img.Url} className="mb-4" alt="" />
                                </div>
                                <div className="col-6">
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                    <hr />
                                </div>
                            </div>)
                        break;

                        default: 
                        break;
                }

                return retval;


            })}

        </div>

    )
}


export default PrintLecture;